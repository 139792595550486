<template>
  <div class="">
    <Parent />
    <v-card class="mt-3">
      <CardHeader>
        <template slot="left">
          <div class=" pa-3">
            <div class="d-flex">
              <v-card
                color="#525252"
                flat
                height="29px"
                class="mr-2"
                width="136px"
              >
                <div class="d-flex justify-center align-center">
                  <v-icon size="17" color="white">$businessSettingWhite</v-icon>
                  <div class="font-18px white--text pl-2">
                    {{ $t("page_business_setting_title") }}
                  </div>
                </div>
              </v-card>
              <div class="dropdown-main-div">
                <DatePickerInput
                  solo
                  flat
                  dense
                  background-color="#D8D5FC"
                  v-model="datePickerInputField.value"
                  :separator="'-'"
                  :min="minDate"
                  :max="maxDate"
                  :field="datePickerInputField"
                  @input="getDataFromApi"
                >
                  <template v-slot:append="{ parent }">
                    <div
                      class="v-input__append-inner mouse-pointer"
                      v-on="parent.on"
                      v-bind="parent.attrs"
                    >
                      <div class="v-input__icon v-input__icon--append">
                        <v-icon>{{ datePickerInputField.icon }}</v-icon>
                      </div>
                    </div>
                  </template>
                </DatePickerInput>
              </div>
            </div>
          </div>
        </template>
      </CardHeader>
      <CardHeader>
        <template slot="left">
          <div class="pl-4 py-3 d-flex align-center">
            <Day
              :title="$t('page_business_setting_store_holiday')"
              :daysCount="noOfRestDay"
              class="mr-2"
            ></Day>
            <Day
              :title="$t('page_business_setting_business_day')"
              :daysCount="noOfBuisnessDay"
              class="mr-2"
            ></Day>
            <template v-if="isEditAble">
              <div class="font-12px gray mr-2">
                {{ $t("page_business_setting_holiday_selection") }}:
              </div>
      
              <div
                v-for="(holiday, index) in holidays"
                :key="index"
                class="mr-2 mouse-pointer chip d-flex align-center justify-center font-12px"
                :class="{ 'selected-holiday': selectedBatch.includes(index)}"
                @click="
                  holiday.type && holiday.type == 'all'
                    ? setAllBusinessOrHoliday(false, index)
                    : setSingleBusinessOrHoliday(index)
                "
              >
                {{ holiday.text }}
              </div>

              <div class="bar ml-2"></div>
              <div
                class="chip mouse-pointer ml-4 d-flex align-center justify-center font-12px"
                :class="{
                  'selected-holiday': selectedBatch > holidays.length - 1
                }"
                @click="setAllBusinessOrHoliday(true)"
              >
                {{ $t("全てを営業日にする") }}
              </div>
            </template>
          </div>
        </template>
      </CardHeader>
      <Table
        :footer="false"
        :headers="headers"
        :items="days"
        :total-records="days.length"
      >
        <template v-slot:item.holiday="{ item }">
          <div
            class="d-flex align-center justify-center"
            :class="{
              holiday: item.status == 2,
              chip: item.status == 2,
              'not-click-able': !isEditAble
            }"
            @click="updateHolidayEntry(item)"
          >
            店休日
          </div>
        </template>
        <template v-slot:item.business="{ item }">
          <div
            class=" enlarge d-flex align-center justify-center"
            :class="{
              business: item.status == 1,
              chip: item.status == 1,
              'not-click-able': !isEditAble
            }"
            @click="updateBusinessEntry(item)"
          >
            営業日
          </div>
        </template>
      </Table>
      <v-card-actions class="py-9">
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">{{ $t("keep") }} </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import Parent from "@/views/admin/Store/Index";
import CardHeader from "@/components/admin/ui/CardHeader";
import Day from "@/views/admin/Business/partials/Day";
import Table from "@/components/admin/partials/Table/Table";
import DatePickerInput from "@/components/admin/ui/DatePickerInput";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  components: {
    Parent,
    CardHeader,
    Day,
    Table,
    DatePickerInput
  },
  data() {
    return {
      minDate: dayjs()
        .subtract(1, "years")
        .toISOString(),
      maxDate: dayjs()
        .add(1, "years")
        .toISOString(),
      selectedBatch: [],
      noOfBuisnessDay: 0,
      noOfRestDay: 0,
      getBusinessYear: [],
      datePickerInputField: {
        name: "registration_date",
        type: "datepicker",
        value: dayjs().format("YYYY/MM"),
        icon: "$purpleDownArrow",
        rules: "",
        locale: "ja",
        pickerType: "month",
        date_format: "YYYY/MM",
        date: dayjs().format("YYYY-MM-DD"),
        menu: false
      },
      days: [],
      holidays: [
        {
          id: 0,
          text: "月"
        },
        {
          id: 1,
          text: "火"
        },
        {
          id: 2,
          text: "水"
        },
        {
          id: 3,
          text: "木"
        },
        {
          id: 4,
          text: "金"
        },
        {
          id: 5,
          text: "土"
        },
        {
          id: 6,
          text: "日"
        },
        {
          id: 7,
          type: "all",
          text: "全て"
        }
      ],
      headers: [
        {
          value: "text",
          text: "日",
          align: "center",
          sortable: false,
          width: "100"
        },
        {
          value: "holiday",
          text: "",
          align: "center",
          sortable: false,
          width: 100
        },
        {
          value: "business",
          text: "",
          align: "center",
          sortable: false,
          width: 100
        },
        {
          text: "",
          sortable: false
        }
      ]
    };
  },
  created() {
    this.getDays();
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getBuisnesses"]),
    isEditAble() {
      /**
       * Editing is possible for the previous month and 12 months after the current month
       */
      const current = dayjs();
      const next = dayjs().add(1, "year");
      const previous = current.subtract(1, "month");
      const prevMonth = previous.format("YYYY-MM");
      const selected = dayjs(this.datePickerInputField.value);
      const selectedMonth = selected.format("YYYY-MM");
      const selectedMonthEqualsPreviousMonth =
        prevMonth === selectedMonth ||
        (selected.isBefore(next) && selected.isAfter(previous));
      if (selectedMonthEqualsPreviousMonth) {
        return true;
      }
      return false;
    }
  },
  methods: {
    getDays() {
      this.selectedBatch = [];
      this.days = [];
      let selectedYear = dayjs(this.datePickerInputField.value).format("YYYY");
      let selectedMonth = dayjs(this.datePickerInputField.value).format("MM");
      let daysInMonth = dayjs(this.datePickerInputField.value).daysInMonth();

      for (let i = 1; i <= daysInMonth; i++) {
        let dayName = dayjs(selectedYear + "-" + selectedMonth + "-" + i)
          .locale("ja")
          .format("ddd");
        this.days.push({
          value: i,
          text: `${selectedMonth}/${i} (${dayName})`,
          day: dayName,
          status: 1
        });
      }
      this.updateHolidayOrBusinessDayCount();
      return this.days;
    },
    updateHolidayEntry(item) {
      if (this.isEditAble) {
        item.status = 2; // Holiday
        this.updateHolidayOrBusinessDayCount();
      }
    },
    updateBusinessEntry(item) {
      if (this.isEditAble) {
        item.status = 1; // Business Day
        this.updateHolidayOrBusinessDayCount();
      }
    },
    setSingleBusinessOrHoliday(index) {
      //this.selectedBatch = index;
      this.selectedBatch.push(index);
      this.days.forEach(element => {
        // element.regular_holiday_or_business_day = 1; // Business Day
        if (element.day == this.holidays[index]?.text) {
          element.status = 2; // Holiday
        }
      });
      this.updateHolidayOrBusinessDayCount();
    },
    setAllBusinessOrHoliday(isHoliday = false, singleDayIndex = false) {
      // Update holiday or business on clicking 'all' or 'all-business'
      this.days.forEach(element => {
        element.status = isHoliday ? 1 : 2;
      });

      // Add selection class to days button based on click
      if (isHoliday) {
        this.selectedBatch = [];
        //this.selectedBatch = this.holidays.length;
        this.selectedBatch.push(this.holidays.length);
      } else {
       //this.selectedBatch = singleDayIndex;
       for(let i=0;i<=6;i++){
         this.selectedBatch.push(i);
       }
        this.selectedBatch.push(singleDayIndex);
      }
      this.updateHolidayOrBusinessDayCount();
    },
    updateHolidayOrBusinessDayCount() {
      //Business days
      this.noOfBuisnessDay = this.days.filter(item => {
        return item.status == 1;
      }).length;

      // Holidays
      this.noOfRestDay = this.days.filter(item => {
        return item.status == 2;
      }).length;
    },
    getDataFromApi() {
      this.$store
        .dispatch("BUSINESS_GET_ALL", {
          id: this.$route.params.shop_id,
          date: this.datePickerInputField.date
        })
        .then(response => {
          this.getBusinessYear = response.data.data.business_day;
          this.patchEditFormValues();
        });
    },

    patchEditFormValues() {
      this.selectedBatch = [];
      this.days = [];
      if (this.getBusinessYear.length > 0) {
        for (let i = 0; i < this.getBusinessYear.length; i++) {
          this.lastUpdateDate = dayjs(
            this.getBusinessYear[i].updated_at
          ).format("YYYY-MM-DD");
          var buisnessDate = this.getBusinessYear[i].date.split("-");
          let buisnessMonth = buisnessDate[1];
          let buisnessDay = buisnessDate[2];

          let dayName = new Date(
            this.getBusinessYear[i].date
          ).toLocaleString("ja-JP", { weekday: "short" });
          this.days.push({
            value: buisnessDay,
            text: buisnessMonth + "/" + buisnessDay + " " + dayName,
            day: dayName,
            status: this.getBusinessYear[i].regular_holiday_or_business_day
          });
        }

        this.updateHolidayOrBusinessDayCount();

        return this.days;
      } else {
        this.getDays();
      }
    },

    submit() {
      this.loading = true;
      let params = [];
      this.days.forEach(day => {
        params.push({
          shop_id: this.$route.params.shop_id,
          date: `${dayjs(this.datePickerInputField.value).format("YYYY-MM")}-${
            day.value
          }`,
          day_name: day.day,
          regular_holiday_or_business_day: day.status
        });
      });

      if (this.getBusinessYear.length > 0) {
        this.$store
          .dispatch("BUSINESS_YEAR_UPDATE", {
            data: params,
            shop_id: this.$route.params.shop_id,
            business_id: 1
          })
          .then(
            response => {
              if (response.status === 200) {
                this.getDataFromApi();
                this.loading = false;
              }
            },
            error => {
              throw error;
            }
          );
      } else {
        params["year"] = dayjs(this.datePickerInputField.value).format("YYYY");
        this.$store
          .dispatch("BUSINESS_YEAR_REGISTER", {
            data: params,
            shop_id: this.$route.params.shop_id
          })
          .then(
            response => {
              if (response.status === 200) {
                this.getDataFromApi();
                this.loading = false;
              }
            },
            error => {
              throw error;
            }
          );
      }
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
