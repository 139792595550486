var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "total pt-2 pb-3 px-4 text-light-grey d-flex align-center"
  }, [_c('div', {
    staticClass: "mr-2 font-12px"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "font-weight-bold font-18px"
  }, [_vm._v(_vm._s(_vm.daysCount))]), _c('div', {
    staticClass: " font-12px ml-1px"
  }, [_vm._v("日")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }