<template>
  <v-menu
    v-if="field"
    v-model="field.menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :prefix="field.prefix"
        :placeholder="field.placeholder"
        :class="field.class"
        v-model="field.value"
        @blur="field.date = parseDate(field.value)"
        v-bind="[$attrs, attrs]"
        v-on="on"
        readonly
        class="font-14px"
        :disabled="disabled"
      >
        <template v-slot:append>
          <slot name="append" :parent="{ on, attrs }"> </slot>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="field.menu = false"
      :locale="field.locale"
      :max="max"
      :min="min"
      :disabled="disabled"
      :day-format="formatDay"
      :type="field.pickerType"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "DatePicker",
  props: {
    field: {
      type: [Object],
      default: () => {
        return {
          menu: false,
          locale: "ja",
          value: "",
          placeholder: ""
        };
      },
      required: true
    },
    errors: {
      type: [String, Object, Array],
      default: () => {
        return "";
      },
      required: false
    },
    separator: {
      type: String,
      default: "/",
      required: false
    },
    max: {
      type: String,
      default: "",
      required: false
    },
    min: {
      type: String,
      default: "",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      date: dayjs().format(`YYYY-MM-DD`),
      daysOfWeek: ["日", "月", "火", "水", "木", "金", "土"]
    };
  },
  watch: {
    date(val) {
      this.field.value = this.formatDate(val);
      this.field.date = this.parseDate(val);
      this.$emit("input", this.formatDate(val));
    }
  },
  created() {
    this.field.value = this.formatDate(this.field.value);
  },
  methods: {
    formatDay(day) {
      return dayjs(day).format("D");
    },
    formatDate(date) {
      if (!date || date == "") return null;

      if (this.field?.date_format) {
        return dayjs(date)
          .locale(this.field.locale ?? "ja")
          .format(this.field.date_format);
      } else {
        return dayjs(date).format(`YYYY${this.separator}MM${this.separator}DD`);
      }
    },
    parseDate(date) {
      if (!date || date == "") return null;

      return dayjs(date).format(`YYYY${this.separator}MM${this.separator}DD`);
    }
  }
};
</script>
