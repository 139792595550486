var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "border",
    attrs: {
      "elevation": _vm.elevation,
      "rounded": _vm.rounded
    }
  }, [_c('v-row', {
    staticClass: "align-center",
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("left")], 2), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("right")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }