<template>
  <div class="total pt-2 pb-3 px-4 text-light-grey d-flex align-center">
    <div class="mr-2 font-12px">{{ title }}</div>
    <div class="font-weight-bold font-18px">{{ daysCount }}</div>
    <div class=" font-12px ml-1px">日</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: [String, Number],
      requried: true
    },
    daysCount: {
      type: [String, Number],
      requried: true
    }
  }
};
</script>
<style lang="scss" src="../style.scss" scoped></style>
