<template>
  <v-sheet :elevation="elevation" :rounded="rounded" class="border">
    <v-row no-gutters class="align-center" justify="center">
      <v-col cols="auto">
        <slot name="left"></slot>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto"> <slot name="right"></slot></v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  props: {
    elevation: {
      type: String,
      default: "0",
      required: false
    },
    rounded: {
      default: true,
      requried: false
    }
  }
};
</script>
<style scoped>
.form-heading {
  height: 100px;
  width: 100%;
}
.border {
  border-bottom: 1px solid #d7d7d7;
  background-color: white;
}
</style>
